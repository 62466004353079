<template>
    <div class="active-list">
      <div class="list">
        <div class="info" v-for="item in list" :key="item.id" @click="toActiveInfo(item)">
          <img class="info-img" :src="item.image">
          <div class="info-content">
            <p class="info-name vertical-ellipsis">{{item.name}}</p>
            <p class="info-name vertical-ellipsis">{{item.intro}}</p>
            <p class="info-date">{{item.publish}}</p>
          </div>
        </div>
        <pagination :num="num" :limit="limit" @getNew="getNew"></pagination>
      </div>
    </div>
  </template>
  <script>
  
  import pagination from "@/components/pc/pagination.vue";
  export default {
    name: "ActiveList",
    components: {
      pagination,
    },
    data() {
      return {
        moduleId: '',
        list: [],
        num: 0,     //数据总个数
        limit: 10,  //一页显示几个
        nowPage: 1, //当前页码
      }
    },
    mounted() {
      this.moduleId = this.$route.params.id;
      this.getList();
    },
    methods: {
      getList(){
        this.$axios.post(`/v1/hh/characterStoryList`, {
          page: this.nowPage,
          per_page: this.limit,
        }).then((res) => {
          this.list = res.data.data;
          this.num = res.data.total;
        });
      },
      //跳转活动文章详情
      toActiveInfo(data){
        if(!data.link){
          this.$router.push('/characterStoryInfo/'+data.id);
        }else{
          window.open(data.link);
        }
      },
      //分页
      getNew(nowPage) {
        this.nowPage = nowPage;
        this.getList();
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .active-list{
    padding: 20px 0;
    background-color: #F7F7F7;
  }
  .list{
    box-sizing: border-box;
    width: 1200px;
    padding: 35px;
    background-color: #FFF;
    margin: 0 auto;
  }
  .info{
    box-sizing: border-box;
    width: 100%;
    height: 205px;
    padding: 16px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover{
      box-shadow: 0px 0px 14px 1px rgba(0,12,40,0.15);
    }
  }
  .info-img{
    width: 313px;
    height: 147px;
    border-radius: 6px;
    margin-right: 36px;
  }
  .info-content{
    width: 760px;
    height: 147px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .info-name{
    font-size: 16px;
    color: #000C28;
    -webkit-line-clamp: 1;
  }
  .info-date{
    height: 20px;
    padding-left: 22px;
    background: url('~@/assets/clock-grey-ico.png') no-repeat left center/auto 16px;
    font-size: 14px;
    color: #999;
    line-height: 20px;
  }
  .page-wrap{
    margin-top: 60px;
  }
  </style>
  